<template>
  <div class="container-outer overwrite">
    <main id="normal">
      <section class="standard-layout settings headings-reset">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link to="/dashboard/customer-index" class="back-link">Naar dashboard</router-link>
              <h1 class="maintitle">Instellingen</h1>
            </div>
            <div class="col-12">
              <div class="mobile d-lg-none">
                <div class="form-group">
                  <select class="form-control select-form-group">
                    <option>Accountgegevens</option>
                    <option>Voorkeuren reservering</option>
                  </select>
                </div>
              </div>
              <div class="desktop d-none d-lg-block">
                <ul class="nav nav-tabs" id="tabContentPills" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="user-tab" data-toggle="tab" href="#user" role="tab" aria-controls="user" aria-selected="true">Accountgegevens</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected="false">Voorkeuren reservering</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="tabContent">
                <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                  <p class="intro-paragraph">Accountgegevens kunnen hieronder worden aangepast.</p>
                  <form class="inner" ref="formUser" v-on:submit.prevent>
                    <h5 class="title">Accountgegevens</h5>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="user-first-name-field" class="label-required">Voornaam</label>
                          <input type="text" id="user-first-name-field" class="form-control" placeholder="Voornaam..." v-model="userEdit.firstName" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="user-last-name-field" class="label-required">Achternaam</label>
                          <input type="text" id="user-last-name-field" class="form-control" placeholder="Achternaam..." v-model="userEdit.lastName" required>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <label for="user-email-field" class="label-required">E-mailadres</label>
                          <input type="email" id="user-email-field" class="form-control" placeholder="E-mailadres..." v-model="userEdit.email" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <label for="user-password-field" class="label-required">Nieuw wachtwoord</label>
                          <input type="password" id="user-password-field" class="form-control" placeholder="Wachtwoord..." autocomplete="off" v-model="userEdit.password" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="form-group">
                          <label for="user-password-check-field" class="label-required">Herhaal wachtwoord</label>
                          <input type="password" id="user-password-check-field" class="form-control" placeholder="Herhaal wachtwoord..." autocomplete="off" v-model="userEdit.passwordCheck" required>
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn-new btn-primary float-right" @click="updateUser">Wijzigingen opslaan</button>
                  </form>
                </div>
                <div class="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                  <p class="intro-paragraph">De onderstaande gegevens zullen standaard worden ingevuld bij het maken van een reservering. Op deze manier kun je zo snel mogelijk een reservering maken. Deze gegevens kunnen altijd aanpegast worden. Hier en bij een reservering zelf.</p>
                  <form class="inner" ref="formCustomerGeneral" v-on:submit.prevent>
                    <h5 class="title">Contactgegevens</h5>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group normal-radio-group">
                          <div class="form-check-outer">
                            <div class="form-check">
                              <input type="radio" id="customer-type-private-radio" name="customerType" class="form-check-input" value="private" v-model="customer.general.type">
                              <label for="customer-type-private-radio" class="form-check-label">Particulier transport</label>
                            </div>
                            <div class="form-check">
                              <input type="radio" id="customer-type-business-radio" name="customerType" class="form-check-input" value="business" v-model="customer.general.type">
                              <label for="customer-type-business-radio" class="form-check-label">Zakelijk transport</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-company-name-field">Bedrijfsnaam</label>
                          <input type="text" id="customer-company-name-field" class="form-control" v-model="customer.general.companyName">
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-coc-field">KvK-nummer</label>
                          <input type="number" id="customer-coc-field" class="form-control" v-model="customer.general.coc">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-first-name-field">Voornaam</label>
                          <input type="text" id="customer-first-name-field" class="form-control" v-model="customer.general.firstName">
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-last-name-field">Achternaam</label>
                          <input type="text" id="customer-last-name-field" class="form-control" v-model="customer.general.lastName">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-email-field">E-mailadres</label>
                          <input type="text" id="customer-email-field" class="form-control" v-model="customer.general.email">
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-phone-field">Telefoonnummer</label>
                          <input type="text" id="customer-phone-field" class="form-control" v-model="customer.general.phone">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="form-group">
                          <label for="customer-postal-code-field" class="label-required">Postcode</label>
                          <input id="customer-postal-code-field" type="text" class="form-control" placeholder="Postcode..." v-model="customer.general.postalCode" @change="setAddress('general')" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <label for="customer-house-number-field" class="label-required">Huisnr.</label>
                          <input id="customer-house-number-field" type="text" class="form-control" placeholder="Huisnr..." v-model="customer.general.houseNumber" @change="setAddress('general')" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <label for="customer-house-number-addition-field">Toev.</label>
                          <input id="customer-house-number-addition-field" type="text" class="form-control" placeholder="Toev..." v-model="customer.general.houseNumberAddition" @change="setAddress('general')">
                        </div>
                      </div>
                    </div>
                    <div class="result result-address" v-if="customer.general.address">
                      <img src="~@/assets/img/icon-marker.svg" alt="marker">
                      <p class="smaller">{{ customer.general.address }}</p>
                    </div>
                    <button type="submit" class="btn-new btn-primary float-right" @click="updateCustomer('general')">Wijzigingen opslaan</button>
                  </form>
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <form class="content-container-inner" ref="formCustomerPickup" v-on:submit.prevent>
                        <h5 class="subtitle">Ophaaladres</h5>
                        <div class="columns-outer inner-columns-outer">
                          <div class="column inner-column split-column">
                            <div class="form-group">
                              <label for="pickup-postal-code-field">Postcode</label>
                              <input type="text" id="pickup-postal-code-field" class="form-control" placeholder="Postcode..." v-model="customer.pickup.postalCode" @change="setAddress('pickup')">
                            </div>
                          </div>
                          <div class="column inner-column split-column double-split">
                            <div class="form-group first-form-group">
                              <label for="pickup-house-number-field">Huisnr.</label>
                              <input type="text" id="pickup-house-number-field" class="form-control" placeholder="Nr..." v-model="customer.pickup.houseNumber" @change="setAddress('pickup')">
                            </div>
                            <div class="form-group second-form-group">
                              <label for="pickup-house-number-addition-field">Toev.</label>
                              <input type="text" id="pickup-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="customer.pickup.houseNumberAddition" @change="setAddress('pickup')">
                            </div>
                          </div>
                        </div>
                        <div class="result" v-show="customer.pickup.address">
                          <img src="~@/assets/img/icon-marker.svg" alt="marker">
                          <span>{{ customer.pickup.address }}</span>
                        </div>
                        <div class="form-group form-check special-check simple-checks">
                          <input type="checkbox" id="pickup-phone-check" name="pickupPhoneCheck" class="form-check-input" @click="customer.pickup.phoneCheck = !customer.pickup.phoneCheck" v-model="customer.pickup.phoneCheck">
                          <label for="pickup-phone-check" class="form-check-label">Telefoonnummer van contactpersoon ter plaatse wijkt af van die van de aanvrager</label>
                          <div class="info-button" :class="{ hovering: isHoveringInfoIcon[1] }">
                            <button class="info-icon" @mouseover="isHoveringInfoIcon[1] = true" @mouseout="isHoveringInfoIcon[1] = false"></button>
                            <div class="info-button-text">
                              <p>Vul 'Ja' in als er een interne lift in het gebouw aanwezig is die de transporteur kan gebruiken om de items omhoog te vervoeren. Controleer ook of de items in de lift passen.</p>
                            </div>
                          </div>
                        </div>
                        <div class="form-group last-form-group" v-if="customer.pickup.phoneCheck">
                          <label for="pickup-contact-phone-field">Telefoonnummer contactpersoon</label>
                          <input type="text" pattern="^(\d{10})$" id="pickup-contact-phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="customer.pickup.phone">
                        </div>
                        <button type="submit" class="btn-new btn-primary float-right" @click="updateCustomer('pickup')">Wijzigingen opslaan</button>
                      </form>
                    </div>
                    <div class="col-12 col-lg-6">
                      <form class="content-container-inner" ref="formCustomerDelivery" v-on:submit.prevent>
                        <h5 class="subtitle">Afleveradres</h5>
                        <div class="columns-outer inner-columns-outer">
                          <div class="column inner-column split-column">
                            <div class="form-group">
                              <label for="delivery-postal-code-field">Postcode</label>
                              <input type="text" id="delivery-postal-code-field" class="form-control" placeholder="Postcode..." v-model="customer.delivery.postalCode" @change="setAddress('delivery')">
                            </div>
                          </div>
                          <div class="column inner-column split-column double-split">
                            <div class="form-group first-form-group">
                              <label for="delivery-house-number-field">Huisnr.</label>
                              <input type="text" id="delivery-house-number-field" class="form-control" placeholder="Nr..." v-model="customer.delivery.houseNumber" @change="setAddress('delivery')">
                            </div>
                            <div class="form-group second-form-group">
                              <label for="delivery-house-number-addition-field">Toev.</label>
                              <input type="text" id="delivery-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="customer.delivery.houseNumberAddition" @change="setAddress('delivery')">
                            </div>
                          </div>
                        </div>
                        <div class="result" v-show="customer.delivery.address">
                          <img src="~@/assets/img/icon-marker.svg" alt="marker">
                          <span>{{ customer.delivery.address }}</span>
                        </div>
                        <div class="form-group form-check special-check simple-checks">
                          <input type="checkbox" id="delivery-phone-check" name="deliveryPhoneCheck" class="form-check-input" @click="customer.delivery.phoneCheck = !customer.delivery.phoneCheck" v-model="customer.delivery.phoneCheck">
                          <label for="delivery-phone-check" class="form-check-label">Telefoonnummer van contactpersoon ter plaatse wijkt af van die van de aanvrager</label>
                          <div class="info-button" :class="{ hovering: isHoveringInfoIcon[2] }">
                            <button class="info-icon" @mouseover="isHoveringInfoIcon[2] = true" @mouseout="isHoveringInfoIcon[2] = false"></button>
                            <div class="info-button-text">
                              <p>Vul 'Ja' in als er een interne lift in het gebouw aanwezig is die de transporteur kan gebruiken om de items omhoog te vervoeren. Controleer ook of de items in de lift passen.</p>
                            </div>
                          </div>
                        </div>
                        <div class="form-group last-form-group" v-if="customer.delivery.phoneCheck">
                          <label for="delivery-contact-phone-field">Telefoonnummer contactpersoon</label>
                          <input type="text" pattern="^(\d{10})$" id="delivery-contact-phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="customer.delivery.phone">
                        </div>
                        <button type="submit" class="btn-new btn-primary float-right" @click="updateCustomer('delivery')">Wijzigingen opslaan</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

  <!-- alert -->

  <div class="alerts alerts-success" role="alert" v-if="showPasswordAlert">
    <div class="image">
      <img src="~@/assets/img/check-white-round.svg" width="27" height="27" alt="check">
    </div>
    <div class="text">
      <p class="bold extra-margin">Wachtwoord succesvol opgeslagen</p>
      <p class="smaller">Je nieuwe wachtwoord is succesvol opgeslagen.</p>
    </div>
  </div>
</template>

<script>

import validateForm from '@/utils/form-validate';

export default {
  props: ['user'],
  data () {
    return {
      saving: false,
      showPasswordAlert: false,
      isHoveringInfoIcon: {
        1: false,
        2: false
      },
      userEdit: {
        'firstName': '',
        'lastName': '',
        'email': '',
        'password': '',
        'passwordCheck': ''
      },
      customer: {
        'user': {
          'firstName': '',
          'lastName': '',
          'email': ''
        },
        'general': {
          'type': 'private',
          'companyName': '',
          'coc': '',
          'firstName': '',
          'lastName': '',
          'email': '',
          'phone': '',
          'postalCode': '',
          'houseNumber': '',
          'houseNumberAddition': '',
          'street': '',
          'city': '',
          'address': ''
        },
        'pickup': {
          'postalCode': '',
          'houseNumber': '',
          'houseNumberAddition': '',
          'street': '',
          'city': '',
          'address': '',
          'phone': '',
          'phoneCheck': false
        },
        'delivery': {
          'postalCode': '',
          'houseNumber': '',
          'houseNumberAddition': '',
          'street': '',
          'city': '',
          'address': '',
          'phone': '',
          'phoneCheck': false
        }
      },
      validAddress: {
        'general': null,
        'pickup': null,
        'delivery': null
      }
    }
  },
  methods: {
    setAddress(type) {
      let validAddress = false;

      if (this.customer[type].postalCode
        && this.customer[type].houseNumber
      ) {
        this.customer[type].postalCode = this.customer[type].postalCode.toUpperCase().replace(/\s/g, '');

        fetch('/api/orders/validate-postal-code/' + this.customer[type].postalCode + '/' + this.customer[type].houseNumber)
          .then(response => response.ok ? response.json() : [])
          .then(data => {

            if (data['valid']) {
              validAddress = true;
              this.validAddress[type] = true;

              this.customer[type].street = data['street'];
              this.customer[type].city = data['city'];
              this.customer[type].address =
                this.customer[type].street
                + ' '
                + this.customer[type].houseNumber
                + this.customer[type].houseNumberAddition
                + ' in ' + this.customer[type].city;
            }
          })
      }

      if (!validAddress) {
        this.customer[type].street = '';
        this.customer[type].city = '';
        this.customer[type].address = '';
        this.validAddress[type] = false;
      }
    },
    getCustomer () {

      fetch('/api/customers/' + this.user['customerId'])
        .then(response => response.ok ? response.json() : this.customer)
        .then(data => {
          Object.assign(this.customer.general, data);
          this.customer.pickup = data.pickup;
          this.customer.delivery = data.delivery;
          Object.assign(this.userEdit, data.user);
        })
    },
    updateUser () {
      this.saving = true;

      fetch('/api/users/' + this.user['id'], {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.userEdit)
      })
        .then(response => {
          this.saving = false;

          if (response.ok) {

            if (this.userEdit.password) {
              this.showPasswordAlert = true;
              this.userEdit.password = '';
              this.userEdit.passwordCheck = '';

              setTimeout(() => {
                this.showPasswordAlert = false;
              }, 3000);
            }
          }
        })
    },
    updateCustomer(type) {
      this.saving = true;

      fetch('/api/customers/' + this.user['customerId'] + '/' + type, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.customer[type])
      })
        .then(() => {
          this.saving = false;
        })
    }
  },
  watch: {
    userEdit: {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formUser'], !this.saving || !this.userEdit.password || this.userEdit.password === this.userEdit.passwordCheck);
      }
    },
    'customer.general': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCustomerGeneral'], !this.saving);
      }
    },
    'customer.pickup': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCustomerPickup'], !this.saving);
      }
    },
    'customer.delivery': {
      deep: true,
      handler: function () {
        validateForm(this.$refs['formCustomerDelivery'], !this.saving);
      }
    }
  },
  created () {
    this.getCustomer();
  }
}

</script>
