// hours format (int to hh:mm)

export function formatHours(hours) {

  if (isNaN(hours)) {
    hours = 0;
  }

  return Math.floor(hours) + ':' + String(Math.round(hours * 60 % 60)).padStart(2, '0');
}


// price format

export function formatPrice(price) {
  return new Intl.NumberFormat(
    'nl-NL',
    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  ).format(price ? price : 0);
}


// price format input

export function formatPriceInput(input) {
  let
    dec = false,
    decCount = 0,
    res = '';

  if (input) {
    input = String(input.replace(/[a-zA-Z]/g, '').match(/[0-9,.-]+/g));
  }

  if (input && input !== 'null') {

    if (input.match(/,.*[,.]/)) {
      input = input.replace(',', '');
    }

    if (input.match(/\..*[,.]/)) {
      input = input.replace('.', '');
    }

    for (let x = 0; x < input.length; x++) {

      if (input[x] === ',' || input[x] === '.') {
        res = res + ',';
        dec = true;
      } else if (dec === false || (dec === true && decCount < 2)) {
        res = res + input[x];

        if (dec === true) {
          decCount++;
        }
      }
    }

    if (res && res !== 'null') {

      if (dec === false) {
        res = res + ',00';
      } else if (decCount === 0) {
        res = res + '00';
      } else if (decCount === 1) {
        res = res + '0';
      }

      res = res.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }

  return res;
}


// price to float

export function priceToFloat(input) {
  return parseFloat(input.replace('.', '').replace(',', '.'));
}


// show label

export function showLabel(searchKey, options) {
  let label = '';

  Object.keys(options).forEach(key => {

    if (options[key].value === searchKey) {
      label = options[key].label;
    }
  });

  return label;
}


// sort object

export function sortObj(obj) {
  return Object.fromEntries(
    Object.entries(obj).sort(([,a], [,b]) => a.localeCompare(b))
  );
}


// get week number

export function getWeekNumber(d, asString = false) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

  const
    yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1)),
    weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

  if (asString) {
    return d.getUTCFullYear() + ',' + String(weekNo).padStart(2, '0');
  } else {
    return [d.getUTCFullYear(), weekNo];
  }
}


// get week options

export function getWeekOptions(d) {

  if (!d) {
    return false;
  }

  let weeks = [];

  const currentWeek = getWeekNumber(new Date(), true);

  while (getWeekNumber(d, true) <= currentWeek) {
    weeks.push({ 'value': getWeekNumber(d, true), 'label': 'Week ' + getWeekNumber(d)[1] + ', ' + getWeekNumber(d)[0] });
    d.setDate(d.getDate() + 7);
  }

  return weeks;
}


// get month options

/**
 * @param {Date} d - input date
 */

export function getMonthOptions(d) {

  if (!d) {
    return false;
  }

  let months = [];

  while (d < new Date()) {
    const monthLabel = d.toLocaleString('nl-NL', { month: 'long' }) + ' ' + String(d.getFullYear());

    months.push({ 'value': String(d.getFullYear()) + ',' + String(d.getMonth() + 1), 'label': monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1) });
    d.setMonth(d.getMonth() + 1);
  }

  return months;
}


// format month: 2021,9 > september 2021

export function formatMonth(month) {
  const monthSplit = month.split(',');

  if (monthSplit.length === 2) {
    const d = new Date();

    d.setFullYear(monthSplit[0], monthSplit[1] - 1);
    return d.toLocaleString('nl-NL', { month: 'long' }) + ' ' + monthSplit[0];
  } else {
    return month;
  }
}


// format local date time from ISO datetime

export function formatDateTimeLocale(datetime) {
  return new Date(datetime).toLocaleString('nl-NL', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })
    + ' om ' + new Date(datetime).toLocaleString('nl-NL', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' });
}


// format date full

export function formatDateFull(date, capitalize = true) {

  if (!date) {
    return '';
  }

  const dateString = new Date(date).toLocaleString('nl-NL', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' });

  return capitalize ? dateString.charAt(0).toUpperCase() + dateString.slice(1) : dateString;
}


// format day period

export function formatDayPeriod(period) {

  switch (period) {
    case 'morning':
      return 'Tussen 08:00 – 12:00';
    case 'afternoon':
      return 'Tussen 12:00 – 17:00';
    case 'evening':
      return 'Tussen 17:00 – 20:00';
    case 'all day':
      return 'Tussen 08:00 – 20:00';
    default:
      return '';
  }
}


// format custom time period

export function formatCustomTimePeriod(time, periodType = 'pickup', lowercase = false) {
  const hour = parseInt(time.split(':')[0]);

  if (isNaN(hour)) {
    return 'Ongeldige tijd';
  }

  if (periodType === 'delivery') {
    return (lowercase ? 't' : 'T') + 'ussen ' +  String(hour - 4).padStart(2, '0') + ':00 - ' + String(hour) + ':00';
  } else {
    return (lowercase ? 't' : 'T') + 'ussen ' +  String(hour).padStart(2, '0') + ':00 - ' + String(hour + 4) + ':00';
  }
}


// get object non-empty count

export function getObjectNonEmpty(obj) {
  return Object.values(obj).filter(x => x.toString() !== '' && x.toString().length).length;
}
