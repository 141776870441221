<template>
  <div class="container-outer overwrite">
    <main id="full" class="white-bg">
      <section class="dashboard headings-reset">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<h1 class="maintitle">{{ salute }} {{ user.name }},</h1>
							<div class="form-outer">
								<form>
									<h4 class="strong calculate-title">Boek je transport</h4>
									<div class="form-group">
                    <div class="icon-container" :class="{ 'search-active': pickup }">
                      <GMapAutocomplete
                        type="text"
                        id="pickup"
                        class="text-input form-control"
                        placeholder="Vanaf (Plaats)"
                        @place_changed="setPlaceId('pickup', $event)"
                        :options="gMapOptions"
                        :value="pickup"
                      />
                      <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="pickup" @click="pickup = ''; placeIds.pickup = ''">
                    </div>
                  </div>
                  <span class="d-none d-lg-flex arrow">
										<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.3469 18L-5.42428e-08 16.7591L7.46 9L-7.32562e-07 1.24093L1.3469 -5.88748e-08L10 9L1.3469 18Z" fill="white"/>
										</svg>
                  </span>
                  <div class="form-group">
                    <div class="icon-container" :class="{ 'search-active': delivery }">
                      <GMapAutocomplete
                        type="text"
                        id="delivery"
                        class="text-input form-control"
                        placeholder="Naar (Plaats)"
                        @place_changed="setPlaceId('delivery', $event)"
                        :options="gMapOptions"
                        :value="delivery"
                      />
                      <img class="search-img" src="@/assets/img/search-remove.svg" alt="remove" v-if="delivery" @click="delivery = ''; placeIds.delivery = ''">
                    </div>
                  </div>
                  <input type="button" class="btn-new btn-black" value="Bereken prijs" @click="startOrder" :disabled="!placeIds.pickup || !placeIds.delivery">
                </form>
							</div>
							<router-link to="/dashboard/customer-transports" class="full-width-link">
								<span>
									<svg width="31" height="38" viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8.54937 2.86947L6.71542 1L0.296875 7.54286L6.71542 14.0857L8.54937 12.2162L5.25738 8.8605H15.6033C18.0731 8.86091 20.4881 9.60762 22.5431 11.0073C24.5988 12.4075 26.2024 14.3986 27.1497 16.7298C28.097 19.0611 28.345 21.6269 27.8619 24.1024C27.3789 26.5778 26.1868 28.8504 24.4378 30.6333C22.6889 32.4161 20.4617 33.6292 18.0384 34.1205C15.6152 34.6119 13.1034 34.3598 10.8201 33.3957C8.53684 32.4316 6.58382 30.7984 5.2091 28.7011C3.83431 26.6037 3.09999 24.137 3.09999 21.6129V21.1479H0.497603V21.6129C0.497603 24.6542 1.38229 27.6279 3.04076 30.1581C4.6993 32.6883 7.05757 34.6618 9.8184 35.8275C12.5793 36.9933 15.6179 37.2984 18.5495 36.704C21.481 36.1096 24.1726 34.6426 26.2843 32.49C28.3958 30.3376 29.8329 27.5962 30.415 24.6131C30.9971 21.63 30.6984 18.5379 29.5564 15.7274C28.4143 12.9167 26.4797 10.5131 23.9958 8.82123C21.5124 7.12974 18.5918 6.22615 15.6033 6.22572H5.25689L8.54937 2.86947Z" fill="#25A268"/>
									<path d="M15.5078 15.5786H12.9054V24.2481H21.2415V21.6133H15.5078V15.5786Z" fill="#25A268"/>
									<path d="M8.54937 2.86947L6.71542 1L0.296875 7.54286L6.71542 14.0857L8.54937 12.2162L5.25738 8.8605H15.6033C18.0731 8.86091 20.4881 9.60762 22.5431 11.0073C24.5988 12.4075 26.2024 14.3986 27.1497 16.7298C28.097 19.0611 28.345 21.6269 27.8619 24.1024C27.3789 26.5778 26.1868 28.8504 24.4378 30.6333C22.6889 32.4161 20.4617 33.6292 18.0384 34.1205C15.6152 34.6119 13.1034 34.3598 10.8201 33.3957C8.53684 32.4316 6.58382 30.7984 5.2091 28.7011C3.83431 26.6037 3.09999 24.137 3.09999 21.6129V21.1479H0.497603V21.6129C0.497603 24.6542 1.38229 27.6279 3.04076 30.1581C4.6993 32.6883 7.05757 34.6618 9.8184 35.8275C12.5793 36.9933 15.6179 37.2984 18.5495 36.704C21.481 36.1096 24.1726 34.6426 26.2843 32.49C28.3958 30.3376 29.8329 27.5962 30.415 24.6131C30.9971 21.63 30.6984 18.5379 29.5564 15.7274C28.4143 12.9167 26.4797 10.5131 23.9958 8.82123C21.5124 7.12974 18.5918 6.22615 15.6033 6.22572H5.25689L8.54937 2.86947Z" stroke="white" stroke-width="0.2"/>
									<path d="M15.5078 15.5786H12.9054V24.2481H21.2415V21.6133H15.5078V15.5786Z" stroke="white" stroke-width="0.2"/>
									</svg>
                  Bekijk transporthistorie en facturen
								</span>
              </router-link>
						</div>
					</div>
				</div>
      </section>
    </main>
  </div>
</template>

<script>

export default {
  props: ['user'],
  data () {
    return {
      gMapOptions: {
        componentRestrictions: {
          country: [
            'nl',
          ]
        }
      },
      pickup: '',
      delivery: '',
      placeIds: {
        'pickup': '',
        'delivery': ''
      },
      customer: {
        'pickupAddress': '',
        'pickupPlaceId': '',
        'deliveryAddress': '',
        'deliveryPlaceId': ''
      }
    }
  },
  methods: {
    setDefaultPlaceId(type, address) {

      fetch('/api/orders/location-place-id?address=' + encodeURIComponent(address))
        .then(response => response.ok ? response.text() : null)
        .then(data => {
          this.setPlaceId(type, {'place_id': data});
          this.customer[type + 'PlaceId'] = data;
        });
    },
    setPlaceId(type, data) {
      this.placeIds[type] = data ? data['place_id'] : '';
    },
    startOrder () {
      this.$router.push({
        path: '/order/',
        hash: (this.placeIds.pickup && this.placeIds.delivery
          ? '#origin=' + this.placeIds.pickup + '&destination=' + this.placeIds.delivery
            + (this.placeIds.pickup === this.customer.pickupPlaceId ? '&pickupDefault=true' : '')
            + (this.placeIds.delivery === this.customer.deliveryPlaceId ? '&deliveryDefault=true': '')
          : '')
      });
    }
  },
  computed: {
    salute () {
      const curDate = new Date();

      if (curDate.getHours() >= 18) {
        return 'Goedenavond';
      } else if (curDate.getHours() >= 12) {
        return 'Goedemiddag';
      } else {
        return 'Goedemorgen';
      }
    }
  },
  mounted () {

    // set default address from customer

    fetch('/api/customers/' + this.user['customerId'])
      .then(response => response.ok ? response.json() : this.customer)
      .then(data => {

        this.customer = data;

        if (this.customer.pickupAddress) {
          this.pickup = this.customer.pickupAddress;
          this.setDefaultPlaceId('pickup', this.pickup);
        }

        if (this.customer.deliveryAddress) {
          this.delivery = this.customer.deliveryAddress;
          this.setDefaultPlaceId('delivery', this.delivery);
        }
      })
  }
};

</script>
