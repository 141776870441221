<template>
  <div class="container-outer overwrite">
    <main id="normal">
			<section class="standard-layout transport-history headings-reset">
				<div class="container">
					<div class="row">
						<div class="col-12">
              <router-link class="back-link" to="/dashboard/customer-index">Naar dashboard</router-link>
							<h1 class="maintitle">Transporthistorie</h1>
              <router-link class="btn btn-primary d-none d-lg-block btn-new" to="/order">Boek een transport</router-link>
						</div>
						<div class="col-12">
              <router-link class="btn btn-primary d-lg-none btn-new" to="/order">Boek een transport</router-link>
						</div>
          </div>
          <div class="row" v-if="loading" id="extra-margin">
            <div class="col-12 text-center">
              <SpinnerBigIcon/>
            </div>
          </div>

          <div class="row extra-margin-top" v-if="!loading && !orders.length">
            <div class="col-12">
              <div class="empty">
                <div class="content-left">
                  <h5 class="">Geen transporten gevonden...</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!loading && orders.length">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table table-striped table-custom table-clients">
									<thead>
										<tr>
                      <th>ID #</th>
											<th>Aantal items</th>
											<th>Ophaaladres</th>
											<th>Afleveradres</th>
											<th>Bedrag</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="order in orders" :key="order.id">
                      <td>{{ order.id }}</td>
											<td>{{ order.itemsCount + ' ' + (order.itemsCount === 1 ? 'item' : 'items') }}</td>
											<td>{{ order.pickupAddress }}</td>
											<td>{{ order.deliveryAddress }}</td>
											<td>&euro; {{ formatPrice(order.priceSubtotal) }}</td>
											<td class="with-btn"><button class="btn btn-black btn-small" @click="downloadInvoice(order.id)" :disabled="downloading">
                        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.99753 0.5V13.0263M6.99753 13.0263L0.734375 6.76316M6.99753 13.0263L13.2607 6.76316" stroke="white" stroke-width="1.5"/>
                          <path d="M0.734375 17.5L13.2607 17.5" stroke="white" stroke-width="1.5"/>
                        </svg>
                      </button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>

import { formatPrice } from '@/utils/format';
import SpinnerBigIcon from '@/components/common/SpinnerBigIcon';

export default {
  components: {
    SpinnerBigIcon
  },
  data () {
    return {
      loading: true,
      downloading: false,
      orders: []
    }
  },
  methods: {
    formatPrice,
    getOrders () {
      this.loading = true;

      fetch('/api/orders')
        .then(response => response.ok ? response.json() : [])
        .then(data => {
          this.orders = data;
          this.loading = false;
        })
    },
    downloadInvoice(orderId) {
      this.downloading = true;

      fetch('/api/orders/' + orderId + '/download-invoice-customer')
        .then(response => response.ok ? response.blob() : null)
        .then(blob => {

          if (blob) {
            const
              elLink = document.createElement('a'),
              fileUrl = window.URL.createObjectURL(blob)

            document.body.appendChild(elLink);
            elLink.href = fileUrl;
            elLink.download = 'Factuur order ' + orderId + '.pdf';
            elLink.click();

            setTimeout(() => {
              window.URL.revokeObjectURL(fileUrl);
              document.body.removeChild(elLink);
            }, 0);
          }

          this.downloading = false;
        })
    }
  },
  created () {
    this.getOrders();
  }
}

</script>
