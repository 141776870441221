// form validation

export default function validateForm(form, preConditionsPassed = true) {

  if (typeof(form) === 'undefined') {
    return;
  }

  // wait until form is visible

  let ic = 0;
  let i = setInterval(function () {

    if (ic === 100 || form.offsetParent !== null) {
      clearInterval(i);

      // get elements

      let valid = typeof(preConditionsPassed) === 'boolean' ? preConditionsPassed : true;
      const
        buttons = form.querySelectorAll('button[type="submit"]'),
        elsWizard = form.querySelectorAll('[data-wz-step]');

      if (elsWizard.length !== 0) {
        let wizardStepsValid = {};

        // loop through elements, check validity

        elsWizard.forEach(elWizard => {
          const stepNumber = elWizard.dataset.wzStep;

          if (!(stepNumber in wizardStepsValid)) {
            wizardStepsValid[stepNumber] = true;
          }

          if (!elWizard.checkValidity()
            || (elWizard.getAttribute('type') === 'hidden' && elWizard.required && !elWizard.value)
          ) {
            wizardStepsValid[stepNumber] = false;
            valid = false;
          }
        });

        // reset next buttons

        for (const step in wizardStepsValid) {

          form.querySelectorAll('[data-wz-next-' + step + ']').forEach(elButton => {
            elButton.setAttribute('data-wz-state', false);
          });
        }

        // set next button disabled

        for (const step in wizardStepsValid) {

          form.querySelectorAll('[data-wz-next-' + step + ']').forEach(elButton => {

            if (elButton.getAttribute('data-wz-state') === 'false' || !elButton.disabled) {
              elButton.disabled = !wizardStepsValid[step];
              elButton.setAttribute('data-wz-state', true);
            }
          });
        }
      } else {

        // loop through hidden inputs, check validity

        if (form.querySelectorAll('input[type="hidden"][value=""][required]').length) {
          valid = false;
        }
      }

      // check fields: required + invalid

      if (!form.checkValidity()) {
        valid = false;
      }

      // set button disable

      if (buttons.length !== 0) {
        buttons.forEach(elButton => elButton.disabled = !valid);
      }
    } else {
      ic++;
    }
  }, 50);
}
